<script lang="tsx">
import type { SlotsType, VNode } from 'vue'
import type { InternalFormElementValue } from '@core-types/components/CoreUIForm'
import type { BaseFormElementProps } from '@core-types/components'
import { BaseUiInput, BaseUiFormLabel, BaseUiFormError, BaseUiFormDescription, BaseUiFormHelp } from '#components'

export type BaseUiFormGroupProps = {
    help?: string
    descriptionAbove?: string
    descriptionBelow?: string

    /**
     * Whether to hide the marker for required inputs or not.
     */
    hideRequired?: boolean
    /**
     * The label of the input.
     * Will get overwritten by the default slot, if present.
     */
    label?: string
    /**
     * Whether not to render the label element.
     * An **`aria-label`** attribute will be used **in case the label element is not rendered**.
     */
    noLabelElement?: boolean
    /**
     * The HTML id of the div with an error message. (used when external error message handling is needed)
     */
    errorId?: string

    /**
     * The HTML id of the div with the description for the input.
     * This is used to link the input with the description.
     */
    descriptionId?: string | string[]
}

type AllowUndefined<T> = {
    [P in keyof T]: T[P] | undefined
}

type RealBaseUiFormGroupProps =
    AllowUndefined<
        BaseUiFormGroupProps & Omit<
            BaseFormElementProps<unknown>, 'formModifiers' | 'modelValue' | 'modelModifiers' | 'descriptionId'
        >
    >


export type BaseUiFormGroupSlots = {
    default: {
        renderLabel: (slotContent: VNode | null, labelOptions?: Partial<RenderLabelOptions>) => VNode | false
        renderAboveDescription: () => VNode | undefined
        renderBelowDescriptionAndError: () => VNode | undefined
        inputId: string
        isInputRequired: boolean
        isInputAriaInvalid: boolean
        inputDescriptionIds: string | undefined
    }
    help: {}
    above: {}
    below: {}
    error: {
        message: string
    }
}

type ComponentOptions = {}

interface RenderLabelOptions {
    textNormal: boolean
    slotAbove: VNode | null
    slotBelow: VNode | null
    hasDefaultSlotContent: boolean
    class: string
}

export function defineComponentBaseUiFormGroup<T>(options?: ComponentOverrideOptions<ComponentOptions, RealBaseUiFormGroupProps, BaseUiFormGroupSlots>) {
    return defineComponent(
        (props: RealBaseUiFormGroupProps, ctx) => {
            const { injected } = useCoreUiFormProvide<any, unknown>()

            const formInputValue = computed<InternalFormElementValue<unknown> | undefined>(() => props.form)

            const errorMessage = computed<string | null>(() => {
                if (!formInputValue.value) return null
                if (!injected?.formErrors) return null
                // @ts-ignore
                return injected.formErrors.value[formInputValue.value?.__f] as string ?? null
            })

            const isHelpSlotRendered = computed<boolean>(() => !!(props.help || ctx.slots.help !== undefined || options?.slots?.help))
            const isErrorMessageRendered = computed<boolean>(() => !!errorMessage.value && !props.errorId)
            const isAboveSlotRendered = computed<boolean>(() => !!(props.descriptionAbove || ctx.slots.above !== undefined || options?.slots?.above))
            const isBelowSlotRendered = computed<boolean>(() => !isErrorMessageRendered.value && !!(props.descriptionBelow || ctx.slots.below !== undefined || options?.slots?.below))

            const isInputAriaInvalid = computed<boolean>(() => !!(isErrorMessageRendered.value || (props.errorId && errorMessage.value) || props.ariaInvalid))

            const inputId = useId()
            const helpId = useId()
            const aboveDescriptionId = useId()
            const belowDescriptionId = useId()
            const _internalErrorId = useId()
            const errorId = computed(() => props.errorId ?? _internalErrorId)

            const inputDescriptionIds = computed<string | undefined>(() => {
                const ids: string[] = []
                if (isHelpSlotRendered.value && helpId) ids.push(helpId)
                if (isAboveSlotRendered.value && aboveDescriptionId) ids.push(aboveDescriptionId)
                if (isBelowSlotRendered.value && belowDescriptionId) ids.push(belowDescriptionId)

                // automatic error
                if (isErrorMessageRendered.value && errorId.value) ids.push(errorId.value)
                // manual external error
                if (props.errorId && errorMessage.value) ids.push(props.errorId)

                if (props.descriptionId) ids.push(...(Array.isArray(props.descriptionId) ? props.descriptionId : [props.descriptionId]))

                return ids.length ? ids.join(' ') : undefined
            })

            const isInputRequired = computed<boolean>(() => {
                return props.required ?? formInputValue.value?.__r ?? false
            })

            function _renderLabel(slotContent: VNode | null, labelOptions?: Partial<RenderLabelOptions>) {
                if (labelOptions?.slotAbove || labelOptions?.slotBelow) {
                    return <div class="sim-form-group__label-wrapper">
                        {labelOptions.slotAbove}

                        <BaseUiFormLabel
                            for={inputId!}
                            class={labelOptions.class}
                            required={isInputRequired.value && !props.hideRequired}
                            textNormal={labelOptions?.textNormal}
                        >
                            {slotContent}
                        </BaseUiFormLabel>

                        {labelOptions.slotBelow}
                    </div>
                }

                return (
                    <BaseUiFormLabel
                        for={inputId!}
                        class={labelOptions?.class}
                        required={isInputRequired.value && !props.hideRequired}
                        textNormal={labelOptions?.textNormal}
                    >
                        {slotContent}
                    </BaseUiFormLabel>
                )
            }

            function renderLabel(slotContent: VNode | null, labelOptions?: Partial<RenderLabelOptions>) {
                const renderLabel: boolean = props.noLabelElement ? false : !!(props.label || labelOptions?.hasDefaultSlotContent)
                return isHelpSlotRendered.value
                    ? (
                        // LABEL WITH HELP
                        <div class="sim-form-group__label-group">
                            {renderLabel && _renderLabel(slotContent, labelOptions)}

                            <BaseUiFormHelp
                                id={helpId!}
                            >
                                {renderSlot(ctx.slots.help, options?.slots?.help, {}, (
                                    <>
                                        {props.help}
                                    </>
                                ))}
                            </BaseUiFormHelp>
                        </div>
                    )
                    : (
                        // NORMAL LABEL
                        renderLabel && _renderLabel(slotContent, labelOptions)
                    )
            }

            function renderAboveDescription() {
                if (isAboveSlotRendered.value) {return (
                    // ABOVE SLOT
                    <BaseUiFormDescription
                        id={aboveDescriptionId!}
                    >
                        {renderSlot(ctx.slots.above, options?.slots?.above, {}, (
                            <>
                                {props.descriptionAbove}
                            </>
                        ))}
                    </BaseUiFormDescription>
                )}
            }

            function renderBelowDescriptionAndError() {
                if (isErrorMessageRendered.value) {
                    return (
                        // ERROR MESSAGE (instead of below slot)
                        <BaseUiFormError
                            error-id={errorId.value}
                        >
                            {renderSlot(ctx.slots.error, options?.slots?.error, {
                                message: errorMessage.value ?? '',
                            }, (
                                <>
                                    {errorMessage.value}
                                </>
                            ))}
                        </BaseUiFormError>
                    )
                }

                if (isBelowSlotRendered.value) {
                    return (
                        // BELOW SLOT (instead of error message)
                        <BaseUiFormDescription
                            id={belowDescriptionId!}
                        >
                            {renderSlot(ctx.slots.below, options?.slots?.below, {}, (
                                <>
                                    {props.descriptionBelow}
                                </>
                            ))}
                        </BaseUiFormDescription>
                    )
                }
            }

            return () => (
                <div
                    class={['sim-form-group', {
                        'sim-form-group--disabled': props.disabled,
                        'sim-form-group--loading': props.loading,
                        'sim-form-group--error': isInputAriaInvalid.value,
                    }]}
                >
                    {renderSlot(ctx.slots.default, options?.slots?.default, {
                        renderLabel: renderLabel,
                        renderAboveDescription: renderAboveDescription,
                        renderBelowDescriptionAndError: renderBelowDescriptionAndError,
                        inputId: inputId,
                        isInputRequired: isInputRequired.value,
                        isInputAriaInvalid: isInputAriaInvalid.value,
                        inputDescriptionIds: inputDescriptionIds.value,
                    })}
                </div>
            )
        },
        {
            props: {
                form: {
                    type: Object as PropType<RealBaseUiFormGroupProps['form']>,
                    default: options?.props?.form?.default,
                    required: options?.props?.form?.required ?? false,
                },
                help: {
                    type: String as PropType<RealBaseUiFormGroupProps['help']>,
                    default: options?.props?.help?.default,
                    required: options?.props?.help?.required ?? false,
                },
                descriptionAbove: {
                    type: String as PropType<RealBaseUiFormGroupProps['descriptionAbove']>,
                    default: options?.props?.descriptionAbove?.default,
                    required: options?.props?.descriptionAbove?.required ?? false,
                },
                descriptionBelow: {
                    type: String as PropType<RealBaseUiFormGroupProps['descriptionBelow']>,
                    default: options?.props?.descriptionBelow?.default,
                    required: options?.props?.descriptionBelow?.required ?? false,
                },
                hideRequired: {
                    type: Boolean as PropType<RealBaseUiFormGroupProps['hideRequired']>,
                    default: options?.props?.hideRequired?.default,
                    required: options?.props?.hideRequired?.required ?? false,
                },
                label: {
                    type: String as PropType<RealBaseUiFormGroupProps['label']>,
                    default: options?.props?.label?.default,
                    required: options?.props?.label?.required ?? false,
                },
                noLabelElement: {
                    type: Boolean as PropType<RealBaseUiFormGroupProps['noLabelElement']>,
                    default: options?.props?.noLabelElement?.default,
                    required: options?.props?.noLabelElement?.required ?? false,
                },
                errorId: {
                    type: String as PropType<RealBaseUiFormGroupProps['errorId']>,
                    default: options?.props?.errorId?.default,
                    required: options?.props?.errorId?.required ?? false,
                },
                descriptionId: {
                    type: [String, Array] as PropType<RealBaseUiFormGroupProps['descriptionId']>,
                    default: options?.props?.descriptionId?.default,
                    required: options?.props?.descriptionId?.required ?? false,
                },

                disabled: {
                    type: Boolean as PropType<RealBaseUiFormGroupProps['disabled']>,
                    default: options?.props?.disabled?.default,
                    required: options?.props?.disabled?.required ?? false,
                },
                loading: {
                    type: Boolean as PropType<RealBaseUiFormGroupProps['loading']>,
                    default: options?.props?.loading?.default,
                    required: options?.props?.loading?.required ?? false,
                },
                required: {
                    type: Boolean as PropType<RealBaseUiFormGroupProps['required']>,
                    default: options?.props?.required?.default,
                    required: options?.props?.required?.required ?? false,
                },
                id: {
                    type: String as PropType<RealBaseUiFormGroupProps['id']>,
                    default: options?.props?.id?.default,
                    required: options?.props?.id?.required ?? false,
                },
                ariaLabel: {
                    type: String as PropType<RealBaseUiFormGroupProps['ariaLabel']>,
                    default: options?.props?.ariaLabel?.default,
                    required: options?.props?.ariaLabel?.required ?? false,
                },
                ariaInvalid: {
                    type: Boolean as PropType<RealBaseUiFormGroupProps['ariaInvalid']>,
                    default: options?.props?.ariaInvalid?.default,
                    required: options?.props?.ariaInvalid?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiFormGroupSlots>,
            emits: {},
        }
    )
}

export default defineComponentBaseUiFormGroup()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormGroup.scss" as *;

</style>
